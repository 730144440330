<template>
  <div>
    <b-card
      no-body
      class="mr-1"
      style=""
    >
      <div class="pl-1 pt-1 ">
        <span class="saj-title">
          {{ $t("Search Filter") }}
        </span>
      </div>
      <div>
        <b-row class="px-1">
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2 pb-2"
          >
            <label class="saj-text">{{ $t("Id") }}:</label>
            <b-form-select
              v-model="selectedEmployeeID"
              :options="empIDList"
              :value="empIDList"
              size="lg"
              class="saj-text"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2 pb-2"
          >
            <label class="saj-text">{{ $t("Name") }}:</label>
            <b-form-input
              id="icons-search"
              v-model="searchname"
              placeholder="Search name..."
              class="saj-text"
              size="lg"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2 pb-2"
          >
            <label class="saj-text">{{ $t("Category") }}:</label>
            <b-form-select
              v-model="selectedCategory"
              :options="categoryList"
              :value="categoryList"
              size="lg"
              class="saj-text"
            />
          </b-col>
          <b-row
            cols="12"
            class="flex-row flex-column mr-1 pl-1"
          >
            <b-col cols="12">
              <b-button
                md="4"
                variant="primary"
                class="saj-title d-flex justify-content-center"
                block
                style="color:black !important;
                    "
              >
                <span class="d-md-none d-lg-block"> {{ $t("Search") }} </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                md="4"
                variant="primary"
                class="saj-title d-flex justify-content-center"
                block
                style="color:black !important;
                    "
              >
                <!-- <feather-icon icon="SearchIcon" /> -->
                <span class="d-md-none d-lg-block"> {{ $t("Clear") }} </span>
              </b-button>
            </b-col>
          </b-row>

        </b-row>
      </div>
      <div class="container">
        <b-table
          responsive
          :items="items"
          :fields="fields"
          bordered
          show-empty
          class="saj-text mt-1 mb-1 "
        >
          <!-- A custom formatted column -->
          <template #head()="data">
            <span
              class="saj-title"
              style=""
            >{{ data.label }}</span>
          </template>
          <template #cell(id)="data">
            <div class="saj-text d-flex justify-content-center">
              {{ data.item.category }}
            </div>
          </template>
          <template #cell(name)="data">
            <div class="saj-text d-flex justify-content-center">
              {{ data.item.category }}
            </div>
          </template>
          <template #cell(category)="data">
            <div class="saj-text d-flex justify-content-center">
              {{ data.item.category }}
            </div>
          </template>
          <template #cell(action)="">
            <div
              class="saj-text d-flex justify-content-center"
              style="
            font-family: Poppins;
            font-weight: 400;
            font-style: normal;
          "
            >
              <router-link
                class="m-0"
                :to="{ name: 'education'}"
              >
                <feather-icon
                  icon="EyeIcon"
                  size="25"
                  style="color: black"
                />
              </router-link></div>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BCard,
  // BCardHeader,
  // BCardBody,
  BRow,
  BCol,
  BTable,
  BButton,
  BFormInput,
  BFormSelect,
  VBPopover,
  BPopover,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // BCardHeader,
    // BCardBody,
    BButton,
    BFormInput,
    BFormSelect,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BPopover,
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },

  data() {
    return {
      approveList: [],
      userId: null,
      employeeList: [],
      searchname: '',
      selectedCategory: '',
      selectedEmployeeID: '',
      empCategory: null,
      allEmpID: [],
      categoryList: [],
      empIDList: [],
      items: [
        {
          employee_number: '001',
          employee_name: 'test1',
          category: '1',
        },
      ],
      fields: [
        {
          key: 'employee_number', label: 'Id', thClass: 'text-center',
        },
        {
          key: 'employee_name', label: 'Name', thClass: 'text-center',
        },
        {
          key: 'category', label: 'Category', thClass: 'text-center',
        },
        {
          key: 'action', label: 'Action', thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },

}
</script>
<style>
.table th, .table td {
    text-align: center;
    color: black;
}
</style>
